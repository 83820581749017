import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { AUTH_TOKENS } from "../utils/constants";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  clientId: `${process.env.REACT_APP_FIREBASE_CLIENT_ID}`,
};

export const getIdToken = async () => {
  if (firebase.auth().currentUser) {
    return await firebase.auth().currentUser.getIdToken();
  }
  return null;
};

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = async () => {
  try {
    await auth.signInWithPopup(provider);
    const user = auth.currentUser;
    if (user) {
      const refreshToken = user.refreshToken;
      const token = await user.getIdToken();
      if (token) {
        document.cookie = `${AUTH_TOKENS.ID_TOKEN}=${token}; domain=.ignitesol.com; path=/;`;
        document.cookie = `${AUTH_TOKENS.REFRESH_TOKEN}=${refreshToken}; domain=.ignitesol.com; path=/;`;
        const nextUrl = getUrlParameter("next");
        window.location.href = nextUrl;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const signInWithCreds = async (username, password) => {
  auth
    .signInWithEmailAndPassword(username, password)
    .then(function (user) {
      auth.currentUser
        .getIdToken(true)
        .then(function (token) {
          const nextUrl = getUrlParameter("next");
          window.location.href = nextUrl;
        })
        .catch(function (error) {
          console.log(error);
        });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const handleSignOut = () => {
  auth.signOut();
  window.location.href = "/";
};

export const getUrlParameter = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const decodeFirebaseIdToken = (token) => {
  try {
    const tokenParts = token.split(".");
    const payload = JSON.parse(atob(tokenParts[1]));
    return payload;
  } catch (error) {
    console.error("Error decoding Firebase ID token:", error);
    return null;
  }
};
export function setCookie(name, value, daysToExpire, domain) {
  const expires = new Date();
  expires.setTime(expires.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expiresUTC = expires.toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expiresUTC}; path=/; domain=${domain || document.domain}`;
}

export default firebase;
