import React from "react";
import { signInWithGoogle } from "services/firebase";
import "./Login.css";

const Login = () => {
  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="show-desktop">
        <div className="login-page">
        <div className="left-side">
          <img src="/images/group.png" alt="collage" />
          <h1 className="heading">IMAGINING POSSIBILITIES</h1>
        </div>
        <div className="right-side">
          <div className="login-container">
            <div className="ignite">
              <img
                src="/images/ignite.png"
                width="267px"
                height="103px"
                alt="Ignite"
              />
            </div>
            <button
              className="google-login-button"
              onClick={handleGoogleSignIn}
            >
              <img
                src="/images/google.png"
                className="google-logo"
                alt="google-logo"
              ></img>
              Log in with Google
            </button>
          </div>
        </div>
      </div>
      </div>
      <div className='show-mobile'>
        <div className='not-found'>
          <img
            src="/images/ignite.png"
            width="252"
            height="102"
            alt="Ignite"
          />
          <h5><a href='https://ignitesol.app.link/timesheet' style={{ color: 'blue' }}>DOWNLOAD TIMESHEET APP</a></h5>
          <h5>Use desktop to access Asset app</h5>
        </div>
      </div>
    </>
  );
};

export default Login;
